<template>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-6 mb-4">
      <p class="mb-1">
        Domain:
      </p>
      <input
        v-model="state.form_data.domain"
        type="text"
        required
        placeholder="Domain"
        class="form-control"
      >
    </div>
    <div class="col-12 col-md-6 col-lg-6 mb-4">
      <p class="mb-1">
        Warning if expire within (days):
      </p>
      <input
        v-model="state.form_data.warning_days"
        type="number"
        placeholder="Days"
        class="form-control"
      >
    </div>
  </div>
</template>

<script>
// This is for Daily, Hourly and Minutely
import { defineComponent, reactive } from 'vue'
export default defineComponent({
  name: 'CheckSSL',
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  setup (props) {
    const defaultData = {
      domain: '',
      warning_days: 10
    }

    const getValue = () => {
      const result = {}
      for (const key in defaultData) {
        if (key === 'warning_days') {
          result[key] = parseInt(state.form_data[key])
        } else {
          result[key] = state.form_data[key]
        }
      }

      return result
    }

    const formData = {
      ...defaultData,
      ...props.options
    }

    const state = reactive({
      form_data: formData
    })
    return { props, state, getValue }
  }
})
</script>
